import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ArrivalOfDrogas = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Arrival of Drogas" />
        <meta
          name="twitter:image"
          content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
        <styl>
          {`html {
            visibility: hidden; 
            opacity: 0;
          }`}
        </styl>
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Arrival of Drogas</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Mar. 10, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            Last year, I launched a{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/drogas"
              target="_blank"
              rel="noopener noreferrer"
            >
              campaign
            </a>{" "}
            to pressure WEWANTDROGAS into releasing his debut mixtape. This
            week, we finally sat down to talk about the coming release, and the
            eight-year odyssey that led to it. What follows is a transcript
            condensed from a two-hour conversation, proving that when you buy
            ink by the barrel like I do, you can move mountains.
          </p>
          <p>
            We met in a private bunker. I offered him some chocolate milk. He
            declined. I put some peppermint oil in the diffuser and we began:
          </p>
          <p>
            <span className="editorsNote">
              What's the title of the project?
            </span>
            <br />
            It's called <i>Lil Black Boy</i>.
          </p>
          <p>
            <span className="editorsNote">When's the release date?</span>
            <br />I don't remember the date, but this Friday. That's March 12th?
            Midnight nigga, be up nigga I'm dropping a video. Tryna see who be
            up at night.
          </p>
          <p>
            <span className="editorsNote">
              Hold up, what video you dropping?
            </span>
            <br />"
            <a
              href="https://soundcloud.com/wewantdrogas/dead-situations"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dead Situations
            </a>
            ". Then later on I'm dropping "45".
          </p>
          <p>
            <span className="editorsNote">
              Tough. And how many tracks is it, what's the total runtime?
            </span>
            <br />
            Seven tracks, each like 2:20 tops, so prolly around (MENTAL MATH) 16
            seconds. (LAUGHTER) I mean 16 minutes. (LAUGHTER) That shit feel
            like 16 seconds though no bullshit.
          </p>
          <p>
            <span className="editorsNote">
              Can we expect any features on it?
            </span>
            <br />
            One, yeah, we got{" "}
            <a
              href="https://soundcloud.com/tunji_ige"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tunji [Ige]
            </a>{" "}
            on that jawn.
          </p>
          <p>
            <span className="editorsNote">When did that collab happen?</span>
            <br />
            I'd say it's been done for a year and a half now. The [rest of the]
            tape was done a little before that, maybe like two years, and that's
            when he sent his part over. Shit is fire, and then we just did some
            additional production on it, my man{" "}
            <a
              href="https://www.instagram.com/macmuseic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Drew
            </a>
            , and it just alleviated the whole… I mean elevated (LAUGHTER), it
            elevated the whole shit.
          </p>
          <p>
            <span className="editorsNote">
              So you're dropping the tape and the first video at the same time?
            </span>
            <br />
            Yeah, so I'm dropping the tape Friday at midnight, and then I'm
            thinking morning time, prolly noon I drop the video. Or drop em both
            at the same time, I really don't give a fuck. Not, 'I don't give a
            fuck', but I just want to put shit out. I feel like I've been
            putting myself in a box for like, the longest. So now I'm ready to
            get this shit out so I can just, completely focus on some new shit.
            I ain't get no new ideas off in so fucking long, niggas just been
            working on the saaame shit for so fucking long.
          </p>
          <p>
            <span className="editorsNote">
              You mean new ideas, like musically? Or...
            </span>
            <br />
            Anything, dog, just… I don't know, that shit is just more fun to me.
            Or not 'more fun', it just come easier to me than making music. I
            like to like, build a diorama, or create the{" "}
            <a
              href="https://www.frederick-scott.com/wewantdrogas/4u"
              target="_blank"
              rel="noopener noreferrer"
            >
              visual
            </a>{" "}
            for the music, and the music is moreso just the score. So I like to
            start with{" "}
            <a
              href="https://www.frederick-scott.com/wewantdrogas/enzo"
              target="_blank"
              rel="noopener noreferrer"
            >
              visual
            </a>{" "}
            and then, build the music around that shit. But, the music been done
            for so fucking long so I had to do it the other way around, the shit
            was not fun. … Cuz that shit even help with songwriting. Yo I been,
            I had writer's block for so fucking long cuz I'm like 'dog we
            working on the same shit I ain't got nothing new to talk about!'
          </p>
          <p>
            <span className="editorsNote">
              I'm glad you took it there cuz that's the direction I was hoping
              to take us in, we bouta get on some heavy psychoanalysis.
            </span>
            <br />
            (LAUGHTER) Fuck it dog.
          </p>
          <p>
            <span className="editorsNote">
              So while I was preparing for this conversation, I listened to the
              interview you did with{" "}
              <a
                href="https://www.2seateraudio.com/radio"
                target="_blank"
                rel="noopener noreferrer"
              >
                2SEATER
              </a>{" "}
              back in 2018. And when you were talking about this tape back then,
              you said that you started working on this project all the way back
              in 2013. (LAUGHTER) <br /> Why so many delays, fuck you think you
              Jay Electronica?
            </span>
            <br />
            Ard so late 2013, after we put out <i>Pink Lemonade</i> with{" "}
            <a
              href="https://soundcloud.com/thegre4toutdoors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Great Outdoors
            </a>{" "}
            and then I think we dropped <i>Sandman</i> on some random shit. [
            <i>Sandman</i>] was mildly successful, not as successful as the
            first jawn. But after that, everybody kinda scattered and went they
            own ways, and that's kinda when I started working on this shit. It
            was called <i>Shades of Red</i> at first.
          </p>
          <p>
            <span className="editorsNote">
              I remember bro, don't forget who you talking to.
            </span>
            <br />
            (LAUGHTER) And I don't even know, what I was even talking about.
            Like, I don't know what the subject matter was. … I don't know,
            prolly was a lot more free than now, cuz now shit is just bound by
            fucking finances and, adult life, shit is trash bruh.
          </p>
          <p>–––RECORD SCRATCH–––</p>
          <p>
            <span className="editorsNote">You need something bro?</span> <br />
            SILIANO: What's that?
            <br />
            DROGAS: Dayum that [chain] hitting! <br />
            SILIANO: (GRINS EAR TO EAR) Forreal? It ain't even no <br /> light
            in here!!! (LAUGHTER) <br />
            <span className="editorsNote">
              Bro you cheesing hard as shit! (LAUGHTER)
            </span>{" "}
            <br />
            SILIANO: Naw but what's that though? <br />
            <span className="editorsNote">
              It's a essential oil diffuser, it don't smell like <br />{" "}
              peppermint in here to you?
            </span>{" "}
            <br />
            SILIANO: Yeah it do, that shit opened up my sinuses, I need <br />{" "}
            one forreal.
          </p>
          <p>–––NEEDLE DROP–––</p>
          <p>
            Naw but yeah where was we at? Yeah at the time it was{" "}
            <i>Shades of Red</i> and I was making some bullshit. You remember
            "Scottie Pippen"? (LAUGHTER) Just was making some horrible ass
            songs, but I was like 'ard, I gotta get outta this lyrical spherical
            shit'. And it's crazy cuz now I miss that shit! Not saying I wanna
            be fucking Éminem (LAUGHTER).
          </p>
          <p>
            <span className="editorsNote">
              So you saying that at the time you were still experimenting?
            </span>
            <br />
            Yeah, yeah, I don't even think I was WEWANTDROGAS yet, I was going
            through a fucking identity crisis. I remember at one point my name
            was 'Money Machine Raptor' (LAUGHTER) like what the fuck. I was just
            like, sooo tryna be{" "}
            <a
              href="https://asaad.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Asaad
            </a>{" "}
            yo. … And now looking back I can say I was on some weird shit
            (LAUGHTER). Back then, I'da argued the shit outta anybody that said
            I was on some weird shit, but I can admit it now.
          </p>
          <p>
            <span className="editorsNote">
              But don't a lot of artists develop their core following by doing
              all that experimenting and evolving out in the open? I feel like
              there's something about the narrative arc of any artist's career–
            </span>
            <br />
            And I wish I did that, my fault to cut you off, but I was tryna be
            tooo fucking mysterious. … If it had been easier for me to be vocal,
            about what I'm doing, and put that shit on view for the world, it
            wouldn't look like 'damn what the fuck you been doing for so long?'
            And that's from everybody, even homies, people I work with, family,
            basically to them it's like 'ard you talented, but you lazy.' … But
            forreal forreal, every fucking day [I was] doing some shit. And all
            [my] shit that people like, I was paying to get that done myself,
            everything, videos, graphics, photoshoots, that shit ain't cheap!
            ... Bro I ain't had a computer since 2011, I had to outsource all
            that. And if I had kept track of everything I done spent and didn't
            use on this shit bruh, I would be very well-off right now (LAUGHTER)
            … So now I'm just ready to, going back to what you was saying, I'm
            at a place now where I'm ready to put those fuck-ups out for people
            to see. I used to be like 'naw let's only post this if it's 100%'
            but it ain't neeever gon be 100%, and I learned that the hard way.
          </p>
          <p>
            <span className="editorsNote">
              It sounds to me like you feel, like, liberated from the burden of
              perfectionism, is that it?
            </span>
            <br />
            Yeah, that's exactly it. And in the next project, it's called{" "}
            <i>Like a Moth to a Flame</i>, I'm exploring those habits and
            circumstances that got me into being like that in the first place. A
            lot of that project is done already, too.
          </p>
          <p>
            <span className="editorsNote">
              Ard I'm bouta go full Nardwuar now. You used to be an Electron,
              how did that shape your experience?
            </span>
            <br />
            (LAUGHTER) I would say, honestly, that high school kinda gave me
            validation. I was actually the reason why Ben Franklin ain't have a
            uniform policy after 2011. My mom was like 'I'm not sending you to
            South Philly High' cuz that's where all my homies from around the
            way went, so I was the only South Philly nigga at a North Philly
            high school, and when I first started I had a cast on my arm and my
            leg from a car accident (LAUGHTER). So long story short, I was
            wearing whatever the fuck I wanted to, but my grades was good, and I
            already had extra credits from my old school, so it was nothing they
            could really say to me. Mr. Johnson, he'll tell you, he had my mom
            come in to discuss it, and she was like 'he ain't really doing shit
            wrong except getting fly!' So then they changed the dress code,
            announced it over the loudspeaker and everything.
          </p>
          <p>
            <span className="editorsNote">That's legendary. (LAUGHTER)</span>
            <br />
            Cuz if you think about it, that uniform shit, it's just like jail!
            So I guess they thought 'maybe if you can come here and be free and
            feel comfortable, maybe you'll get more shit done.' And yeah, as far
            as being an Electron, I didn't wanna play ball, I was tall as shit
            though, so I made varsity off rip (LAUGHTER). I didn't try out bro!
            And that's how I met{" "}
            <a
              href="https://twitter.com/saeedferguson?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noopener noreferrer"
            >
              Saeed [Ferguson]
            </a>
            , ask Saeed!
          </p>
          <p>
            <span className="editorsNote">Bro, what??!</span>
            <br />
            Yeah bro, I was playing varsity for Franklin and I was on the bench
            every game. After a while, they just was like 'bro you wanna keep
            stats?' Saeed was keeping stats, for umm…
          </p>
          <p>
            <span className="editorsNote">
              I'm losing my mind bro, I don't think I've ever heard this story
              in my fucking life.
            </span>
            <br />
            (LAUGHTER) That's how we met. We both had on Cool Grey 11's, and one
            day I was like 'cool shoes' and that's how we met.
          </p>
          <p>
            <span className="editorsNote">
              That's crazy as shit cuz we all really just went to school on the
              same block though. Another thing I was gon ask you is who you
              thought had the best bars back in the Rittenhouse cypher days.
            </span>
            <br />
            Bro it was definitely… Walt, then you, then Declan, then Lijah, then
            I prolly was under Lijah forreal forreal.
          </p>
          <p>
            <span className="editorsNote">
              (LAUGHTER) Bro my Top 1 was Sil, he invented mumble rap back in{" "}
              <br /> 2007 forreal.
            </span>
            <br />
            Damn I really need to get back in touch with a lot of folks from
            back then. That's a big part of what I'm tryna accomplish with the
            whole WEWANT shit, I really just want to be able to pay niggas a
            salary to be creative and do what they do best.
          </p>
          <p>
            <span className="editorsNote">
              And as far as music goes, who do you find yourself listening to or
              inspired by nowadays?
            </span>
            <br />
            It's a few people. I would say, as far as the city goes, I fuck with
            the youngbul{" "}
            <a
              href="https://www.youtube.com/watch?v=hdShA6Blrqk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jah$tar
            </a>
            . It's not the most lyrical, it's not the most cohesive, but you can
            tell like, the nigga is just doing him. It's really nothing like I
            ever heard or seen before. You ever heard of{" "}
            <a
              href="https://soundcloud.com/cari_1417/sets/mood-music"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cari
            </a>
            ? This nigga from Chicago, he hard. Uhh. I can't really throw Whack
            in there, I feel like that's too cliché. (LAUGHTER) Cuz bro Whack is
            hard, and we all know Whack is hard, and she can't do no wrong in my
            eyes. Whack is like the BasedGod at this point. You can't touch her,
            you can't fuck with her, whatever she do is golden, it don't even
            have to be music! But bro all I really listen to is Mary J. Blige.
          </p>

          <p>
            <span className="editorsNote">
              On that note, who do you think of as your core influences?
            </span>
            <br />
            Mary J. Blige for sure. She dropped three bangers off like back to
            back to back! Definitely Pharrell. Drake. Drake is the GOAT, nigga!
            He came out, he knew was gon be the butt of niggas' jokes, and he
            didn't care. Came out wearing bullshit, knew he ain't have the swag,
            would slip a couple bars in like 'I know I ain't got the swag but I
            will outwork any of y'all niggas'. Diddy. Diddy in the sense that he
            just make everything grand. And I would say Busta Rhymes and Missy
            [Elliot]. Can I throw them two in there together? Matter fact, I
            take Diddy out, and that's my five.
          </p>
          <p>
            <span className="editorsNote">
              If you couldn't do music, what medium would you be working in?
            </span>
            <br />
            Honestly, I would just wanna get WEWANT off the ground, establish a
            headquarters, and really be a resource to other artists. And,
            forreal, I need to go back on my biggest influences. My biggest
            inspiration would have to be the{" "}
            <a
              href="https://www.madburyclub.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Madbury Club
            </a>
            . The way they ran that shit… When we put out my first song, [Pink
            Lemonade] wasn't even out yet, I remember the bul Phillip had wrote
            niggas like 'yo this sound like some '93 AZ shit' and that shit, I
            won't ever forget that cuz those niggas is who I looked at like, 'I
            wanna do this when I get older.' I was hurt when they ended that
            shit.{" "}
          </p>
          <p>–––</p>
          <p>
            So there y'all have it, anything precious can be won through
            perseverence (and a splash of cyberbullying). On a personal note,
            yeah I'm excited for the music, but this was a conversation full of
            joy and nostalgia and catharsis. It's a relief to see someone I've
            known for so long free himself from the yoke of 'high potential',
            and conquer his fear of failure. Maybe you can, too.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default ArrivalOfDrogas
